import { useState, useEffect } from 'react';
import HeaderButtonComponent from '../header-button/HeaderButtonComponent';
import SubmenuComponent from './submenu/SubmenuComponent';

const HeaderComponent = () => {


  // Set the initial state of the menu to closed
  const [isOpen, setIsOpen] = useState(false);


  // Function to toggle the menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  // Close the menu when the window is resized to desktop
  useEffect(() => {
    
    // Function to handle window resize
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsOpen(false);
      }
    };
    
    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener when component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, [isOpen]);


  return (
    <div className="sticky top-0 z-50">
      <nav className="flex justify-between items-center h-16 bg-gray-800 text-white relative" role="navigation">
        
        <a href="/" className="pl-8 font-bold animate-fade-in-up">Initiative for Diabetes Cyber Defense (IDCD)</a>

        <div className={`transition-all ease-in-out duration-500 flex ${isOpen ? 'absolute flex-col top-16 lg:bg-black w-full left-0' : 'hidden lg:flex'} lg:flex-row lg:pr-0 lg:w-auto lg:items-center z-50`}>

          <a href="/" className="p-5 bg-black hover:bg-blue-600 lg:hover:bg-transparent lg:bg-transparent">Homepage</a>

          {/*
          <SubmenuComponent
            title="Research Areas"
            isOpen={isOpen}
            links={[
              { href: '/sensors-layer', text: 'Sensors Layer' },
              { href: '/middleware-layer', text: 'Middleware Layer' },
              { href: '/cloud-layer', text: 'Cloud Layer' },
            ]}
          />
          */}

          <SubmenuComponent
            title="Quantitative Data"
            isOpen={isOpen}
            links={[
              { href: '/vulnerability-database', text: 'Vulnerability Database' },
            ]}
          />

          {/*
          <a href="/publications" className="p-5 bg-black hover:bg-blue-600 lg:hover:bg-transparent lg:bg-transparent">Publications</a>
          */}

          {isOpen && <HeaderButtonComponent text="Get Involved" link="/get-involved" />}

        </div>

        {!isOpen &&
          <div className="pr-8 lg:block hidden">
            <HeaderButtonComponent text="Get Involved" link="/get-involved" />
          </div>
        }

        <div className="lg:hidden block">
          <button className="mobile-menu-button p-4" onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>

      </nav>
    </div>
  );
};

export default HeaderComponent;