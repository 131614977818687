import React from 'react';

const PageSectionComponent = (props) => {





    return (
        <div className={`bg-slate-100 py-20 my-10 md:py-44 md:my-10`} id={props.anchor}>
            <div className="w-4/5 md:w-2/3 mx-10 lg:mx-auto items-center">

                <div className="space-y-2 pb-2.5">
                    <div className={`flex items-center`}>
                        <hr className="border-t-2 border-blue-600 w-5 mr-4" />
                        <h2 className="text-sm font-bold text-left text-blue-600">{props.subtitle}</h2>
                    </div>
                    <div>
                        <h2 className="text-3xl font-bold text-left text-gray-800">{props.title}</h2>
                    </div>
                </div>

                <p className="mt-5 leading-7 text-left lg:text-justify font-serif">
                    {props.longtext.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.includes("id='custom-section'") ? (
                                <span dangerouslySetInnerHTML={{ __html: item }} />
                            ) : (
                                <>
                                    {item}
                                    <br />
                                    {index < props.longtext.length - 1 && <br />}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </p>

                {props.listItems &&
                    <ol className="list-decimal list-inside pl-5 space-y-4 pt-5">
                        {props.listItems.map((item, index) => (
                            <li key={index}>
                                <strong>{item.title}:</strong> {item.description}
                            </li>
                        ))}
                    </ol>
                }

            </div>
        </div>
    );
};

export default PageSectionComponent;